.contact-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.contact-heading {
    margin-bottom: 2rem;
}

.contact-heading p {
    line-height: 2;
}

.contact-hello {
    display: flex;
    flex-direction: column;
}

.contact-hello p {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 1rem;
}

.hello-links {
    color: var(--nav-primary-shade);
    margin: .5rem 0;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.hello-links:hover {
    color: var(--nav-primary);
}

/* Form */
.form-box {
    width: 100%;
    color: var(--white);
    font-size: 80%;
}

.form-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.name,
.email {
    width: 40%;
}

.name,
.email,
.message {
    display: flex;
    flex-direction: column;
}

.name label,
.email label,
.message label {
    margin-bottom: 1rem;
}

input,
textarea {
    background-color: transparent;
    border: 0;
    outline: none;
    border-bottom: 1px solid var(--nav-primary);
    padding-bottom: .5rem;
    word-wrap: break-word;
    color: var(--nav-primary-shade);
}


.form-mid {
    margin-bottom: 4rem;
}

.form-btn {
    display: flex;
    justify-content: center;
}

/* Media Queries */
@media screen and (max-width: 900px) {
    .contact-box {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 5rem;
    }

    .right-box {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 550px) {

    .contact-heading p,
    .contact-hello p,
    .hello-links,
    input,
    textarea {
        font-size: 80%;
    }

    .right-box {
        font-size: 70%;
    }

    .contact {
        margin-bottom: 80px;
    }
}
