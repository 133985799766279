.partners {
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 80px;
  max-width: 1200px;
}

.partner_image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .partners {
    padding: 0 20px;
    padding-bottom: 80px;
  }

  .partner_image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .splide__arrow {
    display: none;
  }

  .splide__pagination__page {
    display: none;
  }
}

